import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast } from '../../utils/helpers';
import {
    GET_INTERVIEWER_CALENDAR,
    POST_INTERVIEWER_CALENDAR,
    VERIFY_CALENDAR_REQUEST,
    POST_CANDIDATE_CALENDAR,
    GET_RESCHEDULE_DATA,
    POST_RESCHEDULE_DATA,
    CHECK_CALENDAR_CONNECTION,
    CALENDAR_CONNECT,
    CANCEL_CALENDAR_INTERVIEW,
    RESCHEDULE_CALENDAR_INTERVIEW,
    PANELIST_SLOTS,
    VERIFY_NEW_CALENDAR_REQUEST,
} from '../action/types';
import {
    getInterviewerCalendarSuccess,
    getInterviewerCalendarFailure,
    postInterviewerCalendarSuccess,
    postInterviewerCalendarFailure,
    verifyCalendarRequestSuccess,
    verifyCalendarRequestFailure,
    postCandidateCalendarSuccess,
    postCandidateCalendarFailure,
    getRescheduleDataSuccess,
    getRescheduleDataFailure,
    postRescheduleDataSuccess,
    postRescheduleDataFailure,
    checkCalendarConnectionSuccess,
    checkCalendarConnectionFailure,
    calendarConnectSuccess,
    calendarConnectFailure,
    cancelCalendarInterviewFailure,
    rescheduleCalendarInterviewSuccess,
    rescheduleCalendarInterviewFailure,
    panelistSlotsSuccess,
    panelistSlotsFailure,
    cancelCalendarInterviewSuccess,
    verifyNewCalendarRequestSuccess,
    verifyNewCalendarRequestFailure,
} from '../action';
import API from '../../utils/api';

function* getInterviewerCalendarRequest(action) {
    try {
        const res = yield API.get(`/calendar/interviewerSlots/${action.payload.intId}`);
        if (res.status === 200) {
            yield put(getInterviewerCalendarSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getInterviewerCalendarFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}
function* postInterviewerCalendarRequest(action) {
    try {
        const res = yield API.post('/calendar/updateCalendar/interviewer', action.payload.data);
        if (res.status === 200 || res.status === 201) {
            yield put(postInterviewerCalendarSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(postInterviewerCalendarFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}
function* verifyCalendarRequests(action) {
    try {
        const res = yield API.get(`/calendar/verifyCalendarRequest/${action.payload.calendarId}`);
        if (res.status === 200) {
            yield put(verifyCalendarRequestSuccess(res.data.result));
        }
    } catch (e) {
        yield put(verifyCalendarRequestFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}
function* verifyNewCalendarRequests(action) {
    try {
        const res = yield API.get(`/calendar/new/verifyCalendarRequest/${action.payload.calendarId}`);
        if (res.status === 200) {
            yield put(verifyNewCalendarRequestSuccess(res.data.result));
        }
    } catch (e) {
        yield put(verifyNewCalendarRequestFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}
function* postCandidateCalendarRequest(action) {
    try {
        const res = yield API.post('/calendar/updateCalendar/candidate', action.payload.data);
        if (res.status === 200 || res.status === 201) {
            yield put(postCandidateCalendarSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(postCandidateCalendarFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getRescheduleDataRequest(action) {
    try {
        const res = yield API.get(
            `/calendar/rescheduleInterview/${action.payload.interviewId}/${action.payload.roomId}`
        );
        if (res.status === 200) {
            yield put(getRescheduleDataSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getRescheduleDataFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* postRescheduleDataRequest(action) {
    try {
        const res = yield API.post('/calendar/submitDateAndTime', action.payload.data);
        if (res.status === 200 || res.status === 201) {
            yield toast.success(<Toast msg={'Thank you for scheduling your availability!'} />);
            yield put(postRescheduleDataSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(postRescheduleDataFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* checkCalendarConnectionRequest(action) {
    try {
        const res = yield API.post('/calendarIntegration/CheckCalendarConnection', action?.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(checkCalendarConnectionSuccess(res?.data?.result));
        }
    } catch (e) {
        yield put(checkCalendarConnectionFailure());
    }
}

function* cancelCalendarInterviewRequest(action) {
    try {
        const res = yield API.patch(`/calendarIntegration/${action?.payload?.interviewId}/status/cancelled`, {
            cancelReason: action.payload.reason,
        });
        if (res.status === 200 || res.status === 201) {
            yield put(cancelCalendarInterviewSuccess());
            yield call(action?.payload?.callback);
        } else yield put(cancelCalendarInterviewFailure());
    } catch (e) {
        yield put(cancelCalendarInterviewFailure());
    }
}

function* calendarConnectRequest(action) {
    try {
        const res = yield API.post('/calendarIntegration/connectCalendar', action.payload);
        if (res?.status === 200 || res?.status === 201) {
            yield put(calendarConnectSuccess(res?.data?.result));
            yield call(action?.payload?.callback, res?.data?.result?.emailMismatch, res?.data?.result?.error);
        } else yield put(calendarConnectFailure());
    } catch (e) {
        yield put(calendarConnectFailure());
    }
}

function* rescheduleCalendarInterviewRequest(action) {
    try {
        const res = yield API.post('/calendarIntegration/reschedule-interview', {
            interviewId: action?.payload?.interviewId,
            interviewDate: action.payload?.date,
            interviewTime: action.payload?.start,
            timeDuration: action.payload?.timeDuration,
        });
        if (res?.status === 200 || res?.status === 201) {
            yield put(rescheduleCalendarInterviewSuccess());
            yield call(action.payload?.callback);
        } else yield put(rescheduleCalendarInterviewFailure());
    } catch (e) {
        yield put(rescheduleCalendarInterviewFailure());
    }
}

function* panelistSlotsRequest(action) {
    try {
        const res = yield API.get(
            `/calendarIntegration/panelist-slots?mode=${action.payload.mode}&panelistId=${action.payload.panelistId}`
        );
        if (res.status === 200) {
            yield put(panelistSlotsSuccess(res.data.result));
        }
    } catch (e) {
        yield put(panelistSlotsFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

export function* watchGetInterviewerCalendarAPI() {
    yield takeEvery(GET_INTERVIEWER_CALENDAR, getInterviewerCalendarRequest);
}
export function* watchPostInterviewerCalendarAPI() {
    yield takeEvery(POST_INTERVIEWER_CALENDAR, postInterviewerCalendarRequest);
}
export function* watchVerifyCalendarRequestsAPI() {
    yield takeEvery(VERIFY_CALENDAR_REQUEST, verifyCalendarRequests);
}
export function* watchVerifyNewCalendarRequestAPI() {
    yield takeEvery(VERIFY_NEW_CALENDAR_REQUEST, verifyNewCalendarRequests);
}
export function* watchPostCandidateCalendarAPI() {
    yield takeEvery(POST_CANDIDATE_CALENDAR, postCandidateCalendarRequest);
}
export function* watchGetRescheduleDataAPI() {
    yield takeEvery(GET_RESCHEDULE_DATA, getRescheduleDataRequest);
}
export function* watchPostRescheduleDataAPI() {
    yield takeEvery(POST_RESCHEDULE_DATA, postRescheduleDataRequest);
}
export function* watchCheckCalendarConnectionAPI() {
    yield takeEvery(CHECK_CALENDAR_CONNECTION, checkCalendarConnectionRequest);
}
export function* watchConnectCalendarAPI() {
    yield takeEvery(CALENDAR_CONNECT, calendarConnectRequest);
}
export function* watchCancelCalendarAPI() {
    yield takeEvery(CANCEL_CALENDAR_INTERVIEW, cancelCalendarInterviewRequest);
}
export function* watchRescheduleCalendarAPI() {
    yield takeEvery(RESCHEDULE_CALENDAR_INTERVIEW, rescheduleCalendarInterviewRequest);
}
export function* watchPanelistSlotsAPI() {
    yield takeEvery(PANELIST_SLOTS, panelistSlotsRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetInterviewerCalendarAPI(),
        watchPostInterviewerCalendarAPI(),
        watchVerifyCalendarRequestsAPI(),
        watchVerifyNewCalendarRequestAPI(),
        watchPostCandidateCalendarAPI(),
        watchGetRescheduleDataAPI(),
        watchPostRescheduleDataAPI(),
        watchCheckCalendarConnectionAPI(),
        watchConnectCalendarAPI(),
        watchCancelCalendarAPI(),
        watchRescheduleCalendarAPI(),
        watchPanelistSlotsAPI(),
    ]);
}
